import { isEmpty } from 'ramda';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router';

import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { LocationStateType } from '@/shared/config';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { trackPageView } from '@/shared/lib';
import {
  Button,
  Column,
  Layout,
  MobileLayout,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';

import { billingModel } from '@/entities/billing';
import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';
import { patientModel } from '@/entities/patient';
import { filterStudiesByType, studyModel } from '@/entities/study';
import { userModel } from '@/entities/user';

import { Footer } from '@/features/footer';
import { reportCardsModel } from '@/features/reportCards';

import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';
import { DentalPhotoReportCard } from '@/widgets/DentalPhotoReportCard';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { Header, MobileHeader } from '@/widgets/Header';
import { MobileOrderReportModal } from '@/widgets/MobileOrderReportModal';
import { Order3DSegmentronModal } from '@/widgets/Order3DSegmentronModal';
import { OrderImplantOrOrthoModelModal_DEPRECATED } from '@/widgets/OrderModelModal';
import { OrderReport } from '@/widgets/OrderReport';
import { OrderReportModal } from '@/widgets/OrderReportModal';
import { PatientInfo } from '@/widgets/PatientInfo';
import { PatientModalForm } from '@/widgets/PatientModalForm';
import { ReportCard } from '@/widgets/ReportCard';
import { ReportInfoModal } from '@/widgets/ReportInfoModal';

import styles from './PatientProfile.module.scss';
import { PatientPersonalInfo } from './ui/PatientPersonalInfo/PatientPersonalInfo';
import { ReportCardSkeletons } from './ui/ReportCardSkeletons/ReportCardSkeletons';

export const PatientProfile: FC = () => {
  const { patientID = '' } = useParams();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { isMobile } = useMedia();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const { CanEditPatient, CanViewStudies, CanRequestReport } = useAppSelector(
    patientModel.selectors.selectPatientYourPermissions(patientID),
  );

  const notDeletedStudies = useAppSelector(
    studyModel.selectors.selectNotDeletedStudiesByPatientID(patientID),
  );

  const hasActiveSubscriptionOrPackages = useAppSelector(
    billingModel.selectors.selectHasActiveSubscriptionOrPackages,
  );

  const showDentalPhotosWidget = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_DentalPhotosWidget,
    ),
  );

  const reportCardsLoading = useAppSelector(
    reportCardsModel.selectors.selectLoading,
  );

  const { leftColumn, rightColumn, sortedStructuredReportsAndStudies } =
    useAppSelector(
      reportCardsModel.selectors.selectStructuredReportsAndStudiesByPatientID(
        patientID,
      ),
    );

  const showSkeletons =
    reportCardsLoading === 'idle' || reportCardsLoading === 'pending';

  const locationState = location?.state as LocationStateType;

  const scrollToLastPositionPatientProfile =
    locationState?.lastPositionPatientProfile;

  useEffect(() => {
    if (scrollToLastPositionPatientProfile) {
      window.scrollTo(0, scrollToLastPositionPatientProfile);
    }
  }, [scrollToLastPositionPatientProfile]);

  useEffect(() => {
    trackPageView('PatientProfile Page Viewed');
  }, []);

  // Maybe simpler just group studies by type and render each type when we need?
  const CBCTStudies = filterStudiesByType(StudyType.StudyType_CBCT)(
    notDeletedStudies,
  );
  const IOSStudies = filterStudiesByType(StudyType.StudyType_IOS_Meshes)(
    notDeletedStudies,
  );

  if (isEmpty(user) || patient === undefined) {
    return null;
  }

  const handleEditPatient = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientModalForm,
        data: {
          patientID,
        },
      }),
    );
  };

  const openMobileOrderReportModal = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.MobileOrderReportModal,
      }),
    );
  };

  return (
    <>
      {isMobile ? (
        <MobileLayout>
          <MobileHeader />

          <MobileLayout.Main>
            <PatientPersonalInfo
              canEditPatient={CanEditPatient}
              onEditPatient={handleEditPatient}
            />

            <PatientInfo patientID={patientID} />

            {CanViewStudies && showSkeletons && <ReportCardSkeletons />}

            {CanViewStudies &&
              sortedStructuredReportsAndStudies.length > 0 &&
              sortedStructuredReportsAndStudies.map((reportAndStudies) => (
                <ReportCard
                  key={reportAndStudies.ID}
                  structuredReportsAndStudies={reportAndStudies}
                />
              ))}

            {showDentalPhotosWidget && (
              <DentalPhotoReportCard patientID={patientID} />
            )}
          </MobileLayout.Main>

          {CanRequestReport && hasActiveSubscriptionOrPackages && (
            <MobileLayout.Footer>
              <Button
                className={styles.mobileOrderReportButton}
                onClick={openMobileOrderReportModal}
                icon="plus"
              >
                {formatMessage({
                  id: 'mobileOrderReportModal.orderReport',
                  defaultMessage: 'Order AI report',
                })}
              </Button>
            </MobileLayout.Footer>
          )}
        </MobileLayout>
      ) : (
        <Layout>
          <Layout.Header>
            <Header />
          </Layout.Header>

          <Layout.Content>
            <Layout.Main className={styles.main}>
              <div className={styles.header}>
                <PatientPersonalInfo
                  canEditPatient={CanEditPatient}
                  onEditPatient={handleEditPatient}
                />
              </div>

              <WidgetLayout>
                <Column className={styles.column}>
                  <PatientInfo patientID={patientID} />

                  {CanViewStudies && showSkeletons && <ReportCardSkeletons />}

                  {CanViewStudies &&
                    leftColumn.length > 0 &&
                    leftColumn.map((reportAndStudies) => (
                      <ReportCard
                        key={reportAndStudies.ID}
                        structuredReportsAndStudies={reportAndStudies}
                      />
                    ))}
                </Column>

                <Column className={styles.column}>
                  {CanRequestReport && hasActiveSubscriptionOrPackages && (
                    <WidgetCard>
                      <OrderReport />
                    </WidgetCard>
                  )}

                  {CanViewStudies &&
                    rightColumn.length > 0 &&
                    rightColumn.map((reportAndStudies) => (
                      <ReportCard
                        key={reportAndStudies.ID}
                        structuredReportsAndStudies={reportAndStudies}
                      />
                    ))}

                  {showDentalPhotosWidget && (
                    <DentalPhotoReportCard patientID={patientID} />
                  )}
                </Column>
              </WidgetLayout>
            </Layout.Main>
          </Layout.Content>

          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      )}

      <PatientModalForm />

      <OrderReportModal />

      <MobileOrderReportModal />

      <ConfirmModal />

      <ReportInfoModal />

      <AboutLabelingModal />

      <GetFullAccessModal />

      <OrderImplantOrOrthoModelModal_DEPRECATED
        patientID={patientID}
        cbctStudies={CBCTStudies}
        iosStudies={IOSStudies}
      />

      <Order3DSegmentronModal CBCTStudies={CBCTStudies} />
    </>
  );
};
