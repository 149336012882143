import {
  combinedToolNames,
  CombinedToolNames,
  ToolNames,
} from '@/shared/config';

export const isCombinedTool = (
  control: ToolNames,
): control is CombinedToolNames =>
  [...combinedToolNames].includes(control as CombinedToolNames);
