import { createSelector } from '@reduxjs/toolkit';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';

import { RootState } from '@/app/model/store';

import {
  distributeReportsAndStudies,
  getGroupedReportsBySourceStudyIDs,
  getGroupedReportsBySourceStudyIDsAndType,
  getGroupedReportsWithStudies,
  getSortedStructuredReportsAndStudies,
  getStructuredReportsAndStudies,
} from '../lib';

export const selectStructuredReportsAndStudiesByPatientID = (
  patientID: string,
) =>
  createSelector(
    studyModel.selectors.selectNotDeletedStudiesByPatientID(patientID),
    reportsModel.selectors.selectNotDeletedReportsByPatientID(patientID),
    (studies, reports) => {
      const groupedReportsBySourceStudyIDs =
        getGroupedReportsBySourceStudyIDs(reports);

      const groupedReportsBySourceStudyIDsAndType =
        getGroupedReportsBySourceStudyIDsAndType(
          groupedReportsBySourceStudyIDs,
        );

      const groupedReportsWithStudies = getGroupedReportsWithStudies(
        groupedReportsBySourceStudyIDsAndType,
        studies,
      );

      const structuredReportsAndStudies = getStructuredReportsAndStudies(
        groupedReportsWithStudies,
      );

      const sortedStructuredReportsAndStudies =
        getSortedStructuredReportsAndStudies(structuredReportsAndStudies);

      const [leftColumn, rightColumn] = distributeReportsAndStudies(
        sortedStructuredReportsAndStudies,
      );

      return { leftColumn, rightColumn, sortedStructuredReportsAndStudies };
    },
  );

export const selectLoading = (state: RootState) => state.reports.loading;
