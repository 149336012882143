import cn from 'classnames';

import { Icon } from '../Icon/Icon';

import styles from './ExitFromFullscreenButton.module.scss';

type ExitFromFullscreenButtonProps = {
  className?: string;
  testID?: string;
  onClick: () => void;
};

export const ExitFromFullscreenButton = (
  props: ExitFromFullscreenButtonProps,
) => {
  const { className, testID, onClick } = props;

  return (
    <button
      type="button"
      data-testid={testID}
      className={cn(styles.container, className)}
      onClick={() => {
        document.exitFullscreen();
        onClick();
      }}
    >
      <Icon name="close" size={40} />
    </button>
  );
};
