import { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router';

import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { PATHS, toastErrors } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';

import { organizationModel } from '@/entities/organization';
import { patientModel } from '@/entities/patient';
import { reportsModel } from '@/entities/reports';

export const useReportCheck = (reportID: string) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const isReportNotFoundAfterLoad = useAppSelector(
    reportsModel.selectors.selectIsReportNotFoundAfterLoad(reportID),
  );

  const patientID = useAppSelector(
    patientModel.selectors.selectCurrentPatientID,
  );

  const redirectToDeprecatedPatientProfile = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  useLayoutEffect(() => {
    if (isReportNotFoundAfterLoad) {
      toastCaller({
        type: 'error',
        heading: formatMessage(toastErrors.errorHeading),
        message: formatMessage(toastErrors.defaultError),
        autoClose: 10000,
      });

      navigate(
        generatePath(
          redirectToDeprecatedPatientProfile
            ? PATHS.patientProfile_DEPRECATED
            : PATHS.patient,
          {
            patientID,
          },
        ),
      );
    }
  }, [isReportNotFoundAfterLoad]);
};
