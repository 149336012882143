import { useCallback, useState } from 'react';

import { useAppDispatch } from '@/shared/hooks';
import { ExitFromFullscreenButton } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';

import {
  MedicalImageToolbar,
  ToolbarOptions,
} from '@/widgets/MedicalImageToolbar';

export const Toolbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useAppDispatch();

  const handleOpenEditModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {},
      }),
    );
  }, [dispatch]);

  const controlsOptions: ToolbarOptions[] = [
    {
      control: 'expand',
    },
    {
      control: 'sharpness',
    },
    {
      control: 'brightness',
    },
    {
      control: 'reset',
      notToggle: true,
    },
    {
      control: 'editNumbers',
      onClick: handleOpenEditModal,
      notToggle: true,
    },
  ];

  return (
    <>
      <MedicalImageToolbar toolbarID="main" controlsOptions={controlsOptions} />

      {isExpanded && (
        <ExitFromFullscreenButton onClick={() => setIsExpanded(false)} />
      )}
    </>
  );
};
