import {
  AsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';
import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';

import { FMXNavigationMode, MaskStyleTypes } from '../config/reports.type';

import {
  deleteReport_DEPRECATED,
  setReportMedicalImageViewOptions,
} from './reportSlice.thunks';

type ReportInitialState = DefaultInitialState & {
  flags: {
    isReportRequestPending: boolean;
    isDeleteReportPending: boolean;
    isReportROIPending: boolean;
  };
  maskStyleType: MaskStyleTypes;
  showMasks: boolean;
  showPeriapicalRadiolucencyMasks: boolean;
  toothChartHeight: number;
  navigationMode: FMXNavigationMode;
  activeSubImageIndex: number;
  activeToothID: string;
  activeSubImagesIDs: string[];
  activePageNumber: number;
  disabledSubImagesIDs: string[];
  subImagesIDs: string[];
  ioxRayReportViewOptions: MedicalImageViewOptions;
  toolbarActiveControl: string;
  toolsMode: UltimateMedicalImageMode;
  lastUpdatedCropID: string;
  currentReport: Report | undefined;
  focusedMetaImageID: string;
  hoveredMaskID?: string;
};

const initialState: ReportInitialState = {
  flags: {
    isReportRequestPending: false,
    isDeleteReportPending: false,
    isReportROIPending: false,
  },
  loading: 'idle',
  maskStyleType: MaskStyleTypes.Default,
  showMasks: true,
  showPeriapicalRadiolucencyMasks: true,
  toothChartHeight: 0,
  navigationMode: FMXNavigationMode.MatrixView,
  activeSubImageIndex: 0,
  activePageNumber: 0,
  activeToothID: '',
  activeSubImagesIDs: [],
  disabledSubImagesIDs: [],
  currentReport: undefined,
  subImagesIDs: [],
  ioxRayReportViewOptions: {} as MedicalImageViewOptions,
  toolbarActiveControl: 'view',
  toolsMode: 'view',
  lastUpdatedCropID: '',
  focusedMetaImageID: '',
  hoveredMaskID: '',
};

const sortReports = (a: Report, b: Report) => {
  const aDate = a.Created?.At?.toDate().getTime() ?? 0;
  const bDate = b.Created?.At?.toDate().getTime() ?? 0;

  return bDate - aDate;
};

export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (report) => report.ID,
  sortComparer: sortReports,
});

const reportState = reportsAdapter.getInitialState(initialState);

type ReportState = typeof reportState;

const setNewestOne = (state: ReportState, action: PayloadAction<Report>) => {
  const currentRevisionNumber =
    state.entities[action.payload.ID]?.Revision?.Number ?? 0;
  const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

  if (payloadRevisionNumber > currentRevisionNumber) {
    reportsAdapter.setOne(state, action.payload);

    state.currentReport = action.payload;
  }
};

type StateFlags = keyof typeof initialState.flags;

const reportsSlice = createSlice({
  name: SliceName.reports,
  initialState: reportState,
  reducers: {
    setNewestOne: (state, action: PayloadAction<Report>) => {
      setNewestOne(state as ReportState, action);
    },
    addOne: (state, action: PayloadAction<Report>) => {
      reportsAdapter.addOne(state as ReportState, action.payload);
    },
    addMany: (state, action: PayloadAction<Report[]>) => {
      reportsAdapter.addMany(state as ReportState, action.payload);
    },
    setOne: (state, action: PayloadAction<Report>) => {
      reportsAdapter.setOne(state as ReportState, action.payload);
    },
    removeAll: (state) => {
      reportsAdapter.removeAll(state as ReportState);
    },
    setMany: (state, action: PayloadAction<Report[]>) => {
      reportsAdapter.setMany(state as ReportState, action.payload);
    },
    removeOne: (state, action: PayloadAction<EntityId>) => {
      reportsAdapter.removeOne(state as ReportState, action.payload);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    setMaskStyleType: (state, action) => {
      state.maskStyleType = action.payload;
    },
    setShowMasks: (state, action) => {
      state.showMasks = action.payload;
    },
    setShowPeriapicalRadiolucencyMasks: (state, action) => {
      state.showPeriapicalRadiolucencyMasks = action.payload;
    },
    setToothChartHeight: (state, action) => {
      state.toothChartHeight = action.payload;
    },
    setNavigationMode: (state, action) => {
      state.navigationMode = action.payload;
    },
    setActiveSubImageIndex: (state, action) => {
      state.activeSubImageIndex = action.payload;
    },
    setActivePage: (state, action) => {
      state.activePageNumber = action.payload;
    },
    setActiveToothID: (state, action) => {
      state.activeToothID = action.payload;
    },
    setFocusedMetaImageID: (state, action: PayloadAction<string>) => {
      state.focusedMetaImageID = action.payload;
    },
    setHoveredMaskID: (state, action: PayloadAction<string | undefined>) => {
      state.hoveredMaskID = action.payload;
    },
    setActiveSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.activeSubImagesIDs = action.payload;
    },
    setDisabledSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.disabledSubImagesIDs = action.payload;
    },
    setSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.subImagesIDs = action.payload;
    },
    setToolbarActiveControl: (state, action: PayloadAction<string>) => {
      state.toolbarActiveControl = action.payload;
    },
    setLastUpdatedCropID: (state, action: PayloadAction<string>) => {
      state.lastUpdatedCropID = action.payload;
    },
    setToolsMode: (state, action: PayloadAction<UltimateMedicalImageMode>) => {
      state.toolsMode = action.payload;
    },
    setIOXRayReportViewOptions: (
      state,
      action: PayloadAction<MedicalImageViewOptions>,
    ) => {
      state.ioxRayReportViewOptions = action.payload;
    },
    setCurrentReport: (state, action: PayloadAction<Report>) => {
      state.currentReport = action.payload;
    },
    updateCurrentReport: (state, action: PayloadAction<Report>) => {
      const currentRevisionNumber = state.currentReport?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        state.currentReport = action.payload;
      }
    },
    toggleNavigationMode: (state) => {
      if (state.navigationMode === FMXNavigationMode.MatrixView) {
        state.navigationMode = FMXNavigationMode.ToothChart;
      } else {
        state.navigationMode = FMXNavigationMode.MatrixView;
      }
      state.activeToothID = '';
      state.focusedMetaImageID = '';
      state.toolbarActiveControl = 'view'; // Set toolbar to init state
    },
    removeCurrentReport: (state) => {
      state.currentReport = undefined;
    },
    // TODO: [4|m] Need to make report settings object or even make separate redux slice for it.
    // It,s hard to maintain inside report slice.
    // Also need to review all this props, find deprecated and unused and remove them.
    resetReportViewSettings: (state) => {
      state.showMasks = true;
      state.toothChartHeight = 0;
      state.navigationMode = FMXNavigationMode.MatrixView;
      state.activeSubImageIndex = 0;
      state.activePageNumber = 0;
      state.activeToothID = '';
      state.activeSubImagesIDs = [];
      state.disabledSubImagesIDs = [];
      state.currentReport = undefined;
      state.subImagesIDs = [];
      state.ioxRayReportViewOptions = {} as MedicalImageViewOptions;
      state.toolbarActiveControl = 'view';
      state.toolsMode = 'view';
      state.lastUpdatedCropID = '';
      state.focusedMetaImageID = '';
      state.hoveredMaskID = '';
    },
    reset: () => reportsAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      // @ts-expect-error WARN: C doesn't satisfy AsyncThunkConfig, but this is not public type. Just ignore this or remove this function and use builder itself.
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fulfilledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state.flags[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state.flags[pendingFlagKey] = false;
        if (typeof fulfilledCallback === 'function') {
          fulfilledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state.flags[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(deleteReport_DEPRECATED, 'isDeleteReportPending');

    builder.addCase(
      setReportMedicalImageViewOptions.fulfilled,
      (state, action) => {
        const { Report } = action.payload;
        const isCurrentReport = state.currentReport?.ID === Report?.ID;
        const isCurrentReportUpdated =
          state.currentReport?.Revision?.Number ??
          0 < (Report?.Revision?.Number ?? 0);

        if (Report) {
          if (isCurrentReport && isCurrentReportUpdated) {
            state.currentReport = Report;
          }

          setNewestOne(state as ReportState, {
            type: `${SliceName.reports}/setNewestOne`,
            payload: Report,
          });
        }
      },
    );
  },
});

export const { actions } = reportsSlice;

export default reportsSlice.reducer;
