import cn from 'classnames';
import { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import { LocationStateType } from '@/shared/config';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { scrollToElementByID, trackPageView } from '@/shared/lib';
import {
  Column,
  Layout,
  MobileLayout,
  Spinner,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';

import { assetsModel } from '@/entities/assets';
import { conditionModel } from '@/entities/condition';
import { ConfirmModal } from '@/entities/modal';
import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import { MaskFilters, MaskTooltip } from '@/features/renderMasks';
import { useReportCheck } from '@/features/reportCheck';

import { AddConditionModal } from '@/widgets/AddConditionModal';
import { Conclusion } from '@/widgets/Conclusion';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { MobileHeader } from '@/widgets/Header';
import { Toolbar } from '@/widgets/MedicalImageToolbar';
import { MIRenderModal } from '@/widgets/MIRenderModal';
import { NonDentalFindings } from '@/widgets/NonDentalFindings';
import { ReportHeading } from '@/widgets/ReportHeading';
import { ReportToothCardList } from '@/widgets/ReportToothCardList';
import { TeethNumberingModal } from '@/widgets/TeethNumberingModal';

import styles from './GpReport.module.scss';
import { FooterButtons } from './ui/FooterButtons';
import { GpToothChart } from './ui/GpToothChart/GpToothChart';
import { PanoReformatRender } from './ui/PanoReformatRender/PanoReformatRender';

type ReportProps = {
  className?: string;
};

export const GpReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { reportID = '' } = useParams();
  const location = useLocation();
  const { isMobile } = useMedia();

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportLoading = useAppSelector(reportsModel.selectors.selectLoading);
  const isAllTeethApproved = useAppSelector(
    toothModel.selectors.selectIsAllReportTeethApproved(reportID),
  );
  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );
  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  useReportCheck(reportID);

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;
  const scrollToElementID = locationState?.toothID;
  const lastPositionReport = locationState?.lastPositionReport;
  const isReportSigned = Boolean(report?.Signature?.UserSignatures.length);
  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';
  const showToothChart =
    conditionLoading === 'succeeded' && toothLoading === 'succeeded';
  const CBCTPanoramaAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID),
  );

  const CBCTPanoramaAssetID = CBCTPanoramaAsset?.ID;

  useEffect(() => {
    trackPageView('GpReport Page Viewed');

    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  if (isReportLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  if (!report) {
    return null;
  }

  const reportImageLoading = !CBCTPanoramaAssetID;

  if (isMobile) {
    return (
      <MobileLayout>
        <MobileHeader />
        <MobileLayout.Main>
          <WidgetCard
            className={styles.mobilePanoImageWidget}
            loading={reportImageLoading}
          >
            <PanoReformatRender>
              <Toolbar toolbarID="main" />
              <MaskFilters />
              <MaskTooltip />
            </PanoReformatRender>
          </WidgetCard>

          <WidgetCard className={styles.mobileToothChart}>
            {showToothChart && <GpToothChart />}
          </WidgetCard>

          <NonDentalFindings />
          <ReportToothCardList />
          <Conclusion />
        </MobileLayout.Main>
        <MobileLayout.Footer>
          <FooterButtons
            isReportSigned={isReportSigned}
            isAllTeethApproved={isAllTeethApproved}
            report={report}
          />
        </MobileLayout.Footer>

        {/* Modals */}
        <AddConditionModal />
        <MIRenderModal />
        <GetFullAccessModal />
        <ConfirmModal />
        <TeethNumberingModal />
      </MobileLayout>
    );
  }

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.rightColumn}>
                <NonDentalFindings />
                <ReportToothCardList />
                <Conclusion />
              </Column>
              <Column className={styles.column}>
                <div className={styles.leftFixedColumn}>
                  <ReportHeading
                    report={report}
                    isReportLoading={isReportLoading}
                  />
                  <WidgetCard
                    className={styles.panoImageWidget}
                    loading={reportImageLoading}
                  >
                    <PanoReformatRender>
                      <Toolbar toolbarID="main" />
                      <MaskFilters className={styles.maskFilters} />
                      <MaskTooltip />
                    </PanoReformatRender>
                  </WidgetCard>

                  <WidgetCard className={styles.toothChart}>
                    {showToothChart && <GpToothChart />}
                  </WidgetCard>
                </div>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter className={styles.footerContainer}>
        <FooterButtons
          isReportSigned={isReportSigned}
          isAllTeethApproved={isAllTeethApproved}
          report={report}
        />
      </Layout.Footer>

      {/* Modals */}
      <AddConditionModal />
      <MIRenderModal />
      <GetFullAccessModal />
      <ConfirmModal />
      <TeethNumberingModal />
    </>
  );
};
