import cn from 'classnames';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';

import { LocationStateType } from '@/shared/config';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';
import {
  Button,
  Column,
  Layout,
  Spinner,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';

import { assetsModel } from '@/entities/assets';
import { conditionModel } from '@/entities/condition';
import { ConfirmModal } from '@/entities/modal';
import { getReportSignStatus, reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import { useReportCheck } from '@/features/reportCheck';
import { ToothCardFDA } from '@/features/ToothCard/ui/ToothCardFDA/ToothCardFDA';

import { Conclusion } from '@/widgets/Conclusion';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { Toolbar } from '@/widgets/MedicalImageToolbar';
import { MIRenderModal } from '@/widgets/MIRenderModal';
import { ReportHeading } from '@/widgets/ReportHeading';
import { TeethNumberingModal } from '@/widgets/TeethNumberingModal';

import { PanoReformatRender } from '../GpReport/ui/PanoReformatRender/PanoReformatRender';

import styles from './GpFDAReport.module.scss';
import { useConfirmSignReportModal } from './hooks/useConfirmSignReportModal';
import { ConfirmSignReportModal } from './ui/ConfirmSignReportModal/ConfirmSignReportModal';
import { GpFDAToothChart } from './ui/GpToothChart/GpFDAToothChart';

type ReportProps = {
  className?: string;
};

export const GpFDAReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { patientID = '', reportID = '' } = useParams();
  const location = useLocation();

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportLoading = useAppSelector(reportsModel.selectors.selectLoading);
  const isAllTeethApproved = useAppSelector(
    toothModel.selectors.selectIsAllReportTeethApproved(reportID),
  );
  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );

  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  useReportCheck(reportID);

  const { isMobile } = useMedia();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const scrollToElementID = locationState?.toothID;

  const lastPositionReport = locationState?.lastPositionReport;

  const {
    isConfirmSignReportModalOpen,
    openConfirmSignReportModal,
    closeConfirmSignReportModal,
  } = useConfirmSignReportModal();

  const teeth = useAppSelector(
    toothModel.selectors.selectTeethToShow(reportID),
  );
  const CBCTPanoramaAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID),
  );

  const CBCTPanoramaAssetID = CBCTPanoramaAsset?.ID;

  const reportSignStatus = getReportSignStatus(report);

  const showToothChart =
    conditionLoading === 'succeeded' && toothLoading === 'succeeded';
  const isReportSigned = Boolean(report?.Signature?.UserSignatures.length);
  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';

  useEffect(() => {
    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  if (isReportLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  if (!report) {
    return null;
  }

  const reportImageLoading = !CBCTPanoramaAssetID;

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.column}>
                <div className={styles.leftFixedColumn}>
                  <ReportHeading
                    report={report}
                    isReportLoading={isReportLoading}
                  />
                  <WidgetCard
                    className={styles.panoImageWidget}
                    loading={reportImageLoading}
                  >
                    <PanoReformatRender>
                      <Toolbar toolbarID="main" />
                    </PanoReformatRender>
                  </WidgetCard>

                  <WidgetCard className={styles.toothChart}>
                    {showToothChart && <GpFDAToothChart />}
                  </WidgetCard>
                </div>
              </Column>

              <Column className={styles.rightColumn}>
                {teeth.map((tooth) => (
                  <ToothCardFDA
                    key={tooth.ID}
                    id={tooth.ID}
                    patientID={patientID}
                    reportID={reportID}
                  />
                ))}

                <Conclusion />
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter className={styles.footerContainer}>
        <div className={styles.footerWrapper}>
          {!isReportSigned && (
            <Button
              icon="pen"
              disabled={
                reportSignStatus === 'withoutSign' ||
                !report.YourPermissions?.CanChangeToothApproved
              }
              onClick={openConfirmSignReportModal}
              size={isMobile ? 'medium' : 'large'}
            >
              <FormattedMessage id="report.sign" defaultMessage="Sign" />
            </Button>
          )}
        </div>
      </Layout.Footer>

      <ConfirmSignReportModal
        isAllTeethApproved={isAllTeethApproved}
        reportID={reportID}
        isOpen={isConfirmSignReportModalOpen}
        onCancel={closeConfirmSignReportModal}
      />

      <TeethNumberingModal />

      <MIRenderModal />

      <GetFullAccessModal />

      <ConfirmModal />
    </>
  );
};
