import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CombinedToolNames,
  combinedToolNames,
  SliceName,
  StatelessToolNames,
  ToggledToolNames,
} from '@/shared/config';

export interface ToolbarMIRState {
  [toolbarId: string]: {
    split: boolean;
    invert: boolean;
    expand: boolean;
    hideMarks: boolean;
    activeControl: ToggledToolNames | undefined;
  };
}

const initialState: ToolbarMIRState = {};

const toolbarMIRSlice = createSlice({
  name: SliceName.toolbarMIR,
  initialState,
  reducers: {
    initToolbarMIR: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        initialState?: ToolbarMIRState[string];
      }>,
    ) => {
      const { toolbarID: toolbarId, initialState: toolbarInitialState } =
        action.payload;

      if (!state[toolbarId]) {
        state[toolbarId] = toolbarInitialState ?? {
          split: false,
          invert: false,
          expand: false,
          hideMarks: false,
          activeControl: undefined,
        };
      }
    },
    deleteToolbarMIR: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    split: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        value: boolean;
      }>,
    ) => {
      const toolbar = state[action.payload.toolbarID];

      if (toolbar) {
        toolbar.split = action.payload.value;
      }
    },
    invert: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        value: boolean;
      }>,
    ) => {
      const toolbar = state[action.payload.toolbarID];

      if (toolbar) {
        toolbar.invert = action.payload.value;
      }
    },
    expand: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        value: boolean;
      }>,
    ) => {
      const toolbar = state[action.payload.toolbarID];

      if (toolbar) {
        toolbar.expand = action.payload.value;
      }
    },
    hideMarks: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        value: boolean;
      }>,
    ) => {
      const toolbar = state[action.payload.toolbarID];

      if (toolbar) {
        toolbar.hideMarks = action.payload.value;
      }
    },
    activateTool: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        control: ToggledToolNames | CombinedToolNames | undefined;
        value: boolean;
      }>,
    ) => {
      const toolbarMIR = state[action.payload.toolbarID];

      if (toolbarMIR) {
        if (
          combinedToolNames.includes(
            action.payload.control as CombinedToolNames,
          )
        ) {
          toolbarMIR[action.payload.control as CombinedToolNames] =
            action.payload.value;
        } else {
          toolbarMIR.activeControl = action.payload.value
            ? (action.payload.control as ToggledToolNames)
            : undefined;
        }
      }
    },
    setToolbarState: (
      state,
      action: PayloadAction<{
        toolbarID: string;
        state: ToolbarMIRState[string];
      }>,
    ) => {
      if (state[action.payload.toolbarID]) {
        state[action.payload.toolbarID] = action.payload.state;
      }
    },

    /*
     NOTE: Stateless action is used to invoke actions that will be intercepted by the MIRenderMiddleware.
           This approach provides a consistent interface for interacting with the graphics subsystem.
   */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    statelessAction: (
      _state,
      _action: PayloadAction<{
        toolbarID: string;
        toolName: StatelessToolNames;
        action?: () => void;
      }>,
    ) => {},
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
});

export const { actions } = toolbarMIRSlice;
export default toolbarMIRSlice.reducer;
