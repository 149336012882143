import cn from 'classnames';
import { FC } from 'react';

import { Skeleton } from '@/shared/ui';

import styles from './ReportCardSkeletons.module.scss';

type ReportCardSkeletonsProps = {
  quantity?: number;
  className?: string;
  testID?: string;
};

export const ReportCardSkeletons: FC<ReportCardSkeletonsProps> = (props) => {
  const { quantity = 1, className, testID } = props;

  return Array.from({ length: quantity }).map((_, index) => (
    <Skeleton
      className={cn(styles.container, className)}
      data-testid={testID}
      key={index}
    />
  ));
};
