import { createSelector } from '@reduxjs/toolkit';
import { generatePath } from 'react-router';

import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { Report_2D_ImageType } from '@/shared/api/protocol-ts/model/dto_report_type_2D_common_pb';

import { makeImageViewOptions } from '@/entities/assets';

import { RootState } from '@/app/model/store';

import {
  filterReportByStudyID,
  getReportPath,
  getReportSignStatus,
} from '../lib';

import { reportsAdapter } from './reportSlice';

const reportsSelectors = reportsAdapter.getSelectors(
  (state: RootState) => state.reports,
);

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  reportsSelectors;

export const selectByID = (reportID: string) => (state: RootState) =>
  selectById(state, reportID);

export const selectByStudyID = (studyID: string) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyID(reports, studyID),
  );

// NOTE: should check using reportID, because currentReport can be undefined for some time even after reportDataStream has successfully loaded
export const selectIsReportExist = (reportID: string) =>
  createSelector(selectByID(reportID), (report) => Boolean(report));

export const selectIsReportNotFoundAfterLoad = (reportID: string) =>
  createSelector(
    selectLoading,
    selectIsReportExist(reportID),
    (loading, isReportExist) => loading === 'succeeded' && !isReportExist,
  );

export const selectPanowingDisplayAssetIDByReportID = (reportID: string) =>
  createSelector(
    selectByID(reportID),
    (report) =>
      report?.Specific.case === 'DataPanoBitewings' &&
      report?.Specific.value.PanoStudyAssetID,
  );

const isReportNotDeleted = (report: Report) => !report.Deleted?.Deleted;

const isReportSourceStudyIDsMatch =
  (studyIDs: string[]) => (report: Report) => {
    const reportStudyIDs = report.SourceStudyIDs;

    return (
      reportStudyIDs.length === studyIDs.length &&
      reportStudyIDs.every((id) => studyIDs.includes(id))
    );
  };

const isReportTypeMatches = (
  firstReportType: ReportType,
  secondReportType: ReportType,
) => firstReportType === secondReportType;

export const selectActiveReportsByStudyIDs = (studyIDs: string[]) =>
  createSelector(selectAll, (reports) =>
    reports.filter(
      (report) =>
        isReportNotDeleted(report) &&
        isReportSourceStudyIDsMatch(studyIDs)(report),
    ),
  );

export const selectShowPeriapicalRadiolucencyMasks = (state: RootState) =>
  state.reports.showPeriapicalRadiolucencyMasks;

export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (reports) =>
    reports.filter((report) => report.PatientID === patientID),
  );

export const selectNavigationMode = (state: RootState) =>
  state.reports.navigationMode;

export const selectActiveToothID = (state: RootState) =>
  state.reports.activeToothID;

export const selectDisabledSubImagesIDs = (state: RootState) =>
  state.reports.disabledSubImagesIDs;

export const selectFocusedImageMetaID = (state: RootState) =>
  state.reports.focusedMetaImageID;

export const selectSubImagesIDs = (state: RootState) =>
  state.reports.subImagesIDs;

export const selectReportViewOptions = (reportID: string) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => makeImageViewOptions(report?.MedicalImageFeatures?.ViewOptions),
  );

export const selectCurrentReportType = (state: RootState) =>
  state.reports.currentReport?.Type;

export const selectReportBreadcrumbData = ({
  reportID,
  patientID,
}: {
  reportID: string;
  patientID: string;
}) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => ({
      reportType: report?.Type,
      reportCreatedDate: report?.Created?.At,
      reportPath: report?.Type
        ? generatePath(getReportPath(report?.Type), {
            reportID,
            patientID,
          })
        : undefined,
    }),
  );

export const selectCurrentReport = (state: RootState) =>
  state.reports.currentReport;

export const selectCurrentReportID = (state: RootState) =>
  state.reports.currentReport?.ID;

export const selectLowProbabilityMode = (state: RootState) =>
  state.reports?.currentReport?.Settings?.LowProbabilityMode;

export const selectPreviewSettingsShowTopJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowTopJaw;

export const selectPreviewSettingsShowBottomJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowBottomJaw;

export const selectPreviewSettingsBlackAndWhiteMode = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.BlackAndWhiteMode;

export const selectCurrentReportPanoAssetID = (state: RootState) =>
  state.reports.currentReport?.Specific.case === 'DataPanoGP' &&
  state.reports.currentReport?.Specific.value.PanoStudyAssetID;

export const selectCurrentReportSigned = (state: RootState) =>
  Boolean(state.reports.currentReport?.Signature?.UserSignatures.length);

export const selectCurrentReportCanChangeToothApproved = (state: RootState) =>
  state.reports.currentReport?.YourPermissions?.CanChangeToothApproved;

export const selectCanDeleteReportByID = (reportID: string) =>
  createSelector(selectEntities, (reports) =>
    reportID ? reports[reportID]?.YourPermissions?.CanDelete : false,
  );

export const selectCurrentReportSignStatus = (state: RootState) => {
  if (state.reports.currentReport) {
    return getReportSignStatus(state.reports.currentReport);
  }
  return '';
};

export const selectNotDeletedReportsByPatientID = (patientID: string) =>
  createSelector(selectByPatientID(patientID), (reports) =>
    reports.filter((report) => !report.Deleted?.Deleted),
  );

export const selectLoading = (state: RootState) => state.reports.loading;

export const selectActiveReportsByStudyIDsAndType = (
  studyIDs: string[],
  reportType: ReportType,
) =>
  createSelector(selectActiveReportsByStudyIDs(studyIDs), (reports) =>
    reports.filter((report) => isReportTypeMatches(report.Type, reportType)),
  );

export const selectRotatedImageMetaData = createSelector(
  [selectFocusedImageMetaID, selectCurrentReport],
  (focusedImageMetaID, currentReport) => {
    const isSingleIOXRayImage =
      currentReport?.Specific.case === 'DataIOXRayGP' &&
      currentReport.Specific.value.ImagesMeta.length === 1;
    const currentImageMeta =
      currentReport?.Specific.case === 'DataIOXRayGP' ||
      currentReport?.Specific.case === 'DataPanoBitewings'
        ? currentReport.Specific.value.ImagesMeta.find(
            (meta) =>
              meta.GeneratedAssetID === focusedImageMetaID ||
              meta.StudyAssetID === focusedImageMetaID,
          )
        : null;

    const currentAngle = currentImageMeta?.OrientationAngle || 0;

    // Disable turn tool for panorama in Panowing report
    const isPanowingReport =
      currentReport?.Specific.case === 'DataPanoBitewings';
    const isPanorama =
      isPanowingReport &&
      currentImageMeta?.Type ===
        Report_2D_ImageType.Report_2D_ImageType_PanoramicXRay;
    const isTurnToolDisabled =
      isPanorama || (!isSingleIOXRayImage && !focusedImageMetaID);

    return {
      currentAngle,
      focusedImageMetaID: currentImageMeta?.ID,
      isTurnToolDisabled,
    };
  },
);
