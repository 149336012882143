import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { TYPEWRITER_TIMEOUT } from '@/shared/config/config.ts';
import { useAppSelector, useMedia, useUserTimezone } from '@/shared/hooks';
import { Column, Layout, MobileLayout } from '@/shared/ui/';

import { organizationModel } from '@/entities/organization';

import { Footer } from '@/features/footer';
import { useReportCheck } from '@/features/reportCheck';

import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { Header, MobileHeader } from '@/widgets/Header';

import { usePrintSettings } from './hooks/usePrintSettings';
import styles from './PreviewReport.module.scss';
import { PreviewDocument } from './ui/PreviewDocument/PreviewDocument';
import { SettingsBar } from './ui/SettingsBar/SettingsBar';

type PreviewReportProps = unknown;

export const PreviewReport: FC<PreviewReportProps> = () => {
  const { patientID = '', reportID = '' } = useParams();
  const { isMobile } = useMedia();

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  useReportCheck(reportID);

  const [organizationID, setOrganizationID] = useState(organization?.ID ?? '');

  const { printSettings, updatePrintSettings, isLoading, showLowProbability } =
    usePrintSettings(reportID);

  const isPrintSettingsExist = printSettings !== undefined;

  const userTimezone = useUserTimezone();

  useEffect(() => {
    if (organization?.ID) {
      setOrganizationID(organization?.ID);
    }
  }, [organization]);

  useEffect(() => {
    setTimeout(() => {
      window.typewriterReadyToPDF = true;
    }, TYPEWRITER_TIMEOUT);
  }, []);

  if (isMobile) {
    return (
      <>
        <MobileLayout>
          <MobileHeader />
          <MobileLayout.Main>
            {isPrintSettingsExist && (
              <>
                <SettingsBar
                  reportID={reportID}
                  settings={printSettings}
                  onChange={updatePrintSettings}
                  isLoading={isLoading}
                  patientID={patientID}
                />
                <PreviewDocument
                  reportID={reportID}
                  showLowProbability={showLowProbability}
                  settings={printSettings}
                  organizationID={organizationID}
                  userTimezone={userTimezone}
                />
              </>
            )}
          </MobileLayout.Main>
        </MobileLayout>

        {/* Modals */}
        <GetFullAccessModal />
        <AboutLabelingModal />
      </>
    );
  }

  return (
    <Layout>
      <Layout.Header className={styles.header}>
        <Header fullWidth />
      </Layout.Header>

      <Layout.Content
        className={cn(styles.content, styles.previewReportContent)}
      >
        <Layout.Sider className={styles.sider}>
          {isPrintSettingsExist && (
            <SettingsBar
              reportID={reportID}
              settings={printSettings}
              onChange={updatePrintSettings}
              isLoading={isLoading}
              patientID={patientID}
            />
          )}
        </Layout.Sider>

        <Layout.Main>
          <Column className={styles.previewDocument}>
            {isPrintSettingsExist && (
              <PreviewDocument
                reportID={reportID}
                showLowProbability={showLowProbability}
                settings={printSettings}
                organizationID={organizationID}
                userTimezone={userTimezone}
              />
            )}
          </Column>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      <GetFullAccessModal />

      <AboutLabelingModal />
    </Layout>
  );
};
