// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/billing_new/dto_services_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum com.diagnocat.billing_new.ServiceType
 */
export const ServiceType = proto3.makeEnum(
  "com.diagnocat.billing_new.ServiceType",
  [
    {no: 0, name: "ServiceTypeInvalidValue"},
    {no: 1, name: "ServiceType_Upload_CBCT"},
    {no: 2, name: "ServiceType_Upload_IOXRay"},
    {no: 3, name: "ServiceType_Upload_Panorama"},
    {no: 4, name: "ServiceType_Upload_STL"},
    {no: 5, name: "ServiceType_Upload_DentalPhoto"},
    {no: 6, name: "ServiceType_Upload_Documents"},
    {no: 100, name: "ServiceType_Order_CBCT_GP_Analysis"},
    {no: 101, name: "ServiceType_Order_CBCT_Endodontic_Analysis"},
    {no: 102, name: "ServiceType_Order_CBCT_Implantology_Analysis"},
    {no: 103, name: "ServiceType_Order_CBCT_ThirdMolar_Analysis"},
    {no: 104, name: "ServiceType_Order_CBCT_STL_Analysis"},
    {no: 105, name: "ServiceType_Order_CBCT_Orthodontic_Analysis"},
    {no: 106, name: "ServiceType_Order_CBCT_IOS_Superimposition_Analysis"},
    {no: 107, name: "ServiceType_Order_IOS_Segmentation_Analysis"},
    {no: 108, name: "ServiceType_Order_IOXRay_GP_Analysis"},
    {no: 109, name: "ServiceType_Order_Panoramic_GP_Analysis"},
    {no: 110, name: "ServiceType_Order_DentalPhoto_GP_Analysis"},
    {no: 111, name: "ServiceType_Order_ImplantStudio_Analysis"},
    {no: 112, name: "ServiceType_Order_OrthoStudio_Analysis"},
    {no: 200, name: "ServiceType_Enable_CollaborationTool"},
    {no: 201, name: "ServiceType_Enable_STLViewer"},
    {no: 202, name: "ServiceType_Enable_DesktopApp"},
    {no: 203, name: "ServiceType_Enable_3DModelsWithConditions"},
    {no: 204, name: "ServiceType_Enable_SuggestedTreatment"},
  ],
);

/**
 * @generated from enum com.diagnocat.billing_new.ServiceGroupName
 */
export const ServiceGroupName = proto3.makeEnum(
  "com.diagnocat.billing_new.ServiceGroupName",
  [
    {no: 0, name: "ServiceGroupNameInvalidValue"},
    {no: 1, name: "ServiceGroupNameEmpty"},
    {no: 2, name: "ServiceGroupNameAnalysis3D"},
    {no: 3, name: "ServiceGroupNameAnalysis2D"},
    {no: 4, name: "ServiceGroupNameUpload3D"},
    {no: 5, name: "ServiceGroupNameUpload2D"},
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Service
 */
export const Service = proto3.makeMessageType(
  "com.diagnocat.billing_new.Service",
  () => [
    { no: 1, name: "Types", kind: "enum", T: proto3.getEnumType(ServiceType), repeated: true },
    { no: 2, name: "Group", kind: "enum", T: proto3.getEnumType(ServiceGroupName) },
    { no: 3, name: "Visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "Countable", kind: "message", T: Service_CountableKind, oneof: "Kind" },
    { no: 5, name: "Enabler", kind: "message", T: Service_EnablerKind, oneof: "Kind" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Service.CountableKind
 */
export const Service_CountableKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.Service.CountableKind",
  () => [
    { no: 1, name: "Limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "Leftover", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "Service_CountableKind"},
);

/**
 * @generated from message com.diagnocat.billing_new.Service.EnablerKind
 */
export const Service_EnablerKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.Service.EnablerKind",
  [],
  {localName: "Service_EnablerKind"},
);

