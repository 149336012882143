import { useMemo } from 'react';

import {
  AssetContent_Study_CBCT_CBCT_Metadata,
  GeneratedReportAsset,
} from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { DICOM_MIME_TYPE } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { getName } from '@/shared/lib';

import { assetsModel, getFileSrc } from '@/entities/assets';
import { patientModel } from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  toothModel,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';
import { userModel } from '@/entities/user';

import { UsePreviewDocument } from '../config';
export const usePreviewDocument = (options: {
  reportID: string;
  showBottomJaw: boolean;
  showTopJaw: boolean;
}): UsePreviewDocument => {
  const { reportID, showBottomJaw, showTopJaw } = options;

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);
  const patient = useAppSelector(patientModel.selectors.selectCurrentPatient);
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportLoading = useAppSelector(reportsModel.selectors.selectLoading);
  const patientLoading = useAppSelector(patientModel.selectors.selectLoading);
  const ROITeeth = useAppSelector(
    toothModel.selectors.selectReportROITeeth(reportID),
  );
  const allTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID),
  );
  const ROI = useAppSelector(
    toothModel.selectors.selectISONumbersByReportIDAndROI(reportID),
  );
  const assetByStudyID = useAppSelector(
    assetsModel.selectors.selectAssetByStudyID(report?.SourceStudyIDs[0] ?? ''),
  );

  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';
  const isPatientLoading =
    patientLoading === 'idle' || patientLoading === 'pending';
  const isReportSigned = Boolean(report?.Signature?.UserSignatures.length);

  const patientFullName = getName(
    patient?.PersonalData?.FirstName,
    patient?.PersonalData?.LastName,
    { userLocale },
  );

  const panoImage =
    assetByStudyID?.Kind.case === 'Study' &&
    assetByStudyID.Kind.value.Content.case === 'PanoramicXRay'
      ? assetByStudyID.Kind.value.Content.value.PanoramaImage
      : undefined;

  const isDICOM = panoImage?.MIME === DICOM_MIME_TYPE;

  const panoImageURL = useMemo(
    () => getFileSrc(panoImage?.ID ?? ''),
    [panoImage],
  );

  const CBCTPanoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const CBCTPanoImageID =
    CBCTPanoImage?.Content.case === 'CBCTGPPanoramaReformatGeneral'
      ? CBCTPanoImage.Content.value.PanoramaSingle
      : undefined;

  const CBCTPanoImageUrl = useMemo(
    () => getFileSrc(CBCTPanoImageID?.ID ?? ''),
    [CBCTPanoImageID?.ID],
  );

  const studyMeta = useAppSelector(
    assetsModel.selectors.selectCBCTMetaDataByStudyID(
      report?.SourceStudyIDs[0] ?? '',
    ),
  );

  const isLoading = isReportLoading || isPatientLoading;

  const filteredTeeth = ROITeeth.filter((tooth) => {
    const ISONumber = tooth?.Numeration?.ISO ?? 0;

    return (
      (showTopJaw && UPPER_JAW_TEETH_ISO_NUMBERS.includes(ISONumber)) ||
      (showBottomJaw && LOWER_JAW_TEETH_ISO_NUMBERS.includes(ISONumber))
    );
  });

  return {
    teeth: filteredTeeth,
    allTeeth,
    report,
    patient,
    CBCTPanoImage: CBCTPanoImage ?? ({} as GeneratedReportAsset),
    CBCTPanoImageUrl,
    panoImageURL,
    ROI,
    patientFullName,
    isReportSigned: !!isReportSigned,
    studyMeta: studyMeta ?? ({} as AssetContent_Study_CBCT_CBCT_Metadata),
    isLoading,
    isDICOM,
  };
};
