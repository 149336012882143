import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/store';
import { Tooth_SuggestedTreatmentStatus } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { Button } from '@/shared/ui';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';

import styles from './SuggestedTreatmentButton.module.scss';

type SuggestedTreatmentButtonProps = {
  className?: string;
  testID?: string;
  toothID: string;
  closeTextarea?: () => void;
};

export const SuggestedTreatmentButton = (
  props: SuggestedTreatmentButtonProps,
) => {
  const { className, testID, toothID, closeTextarea } = props;

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const suggestedTreatment = useAppSelector(
    toothModel.selectors.selectToothSuggestedTreatment(toothID),
  );

  const suggestedStatus = suggestedTreatment?.Status;

  const handleGenerateSuggestedToothTreatment = async () => {
    const { Tooth, Report } = await dispatch(
      reportsModel.thunks.generateSuggestedToothTreatment({
        ToothID: toothID,
      }),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }

    if (Tooth) {
      dispatch(toothModel.actions.setNewestOne(Tooth));
    }

    closeTextarea?.();
  };

  const isRegenerate =
    suggestedStatus === Tooth_SuggestedTreatmentStatus.DONE ||
    suggestedStatus === Tooth_SuggestedTreatmentStatus.FAILED;
  const isGenerate = suggestedStatus === Tooth_SuggestedTreatmentStatus.NONE;
  const isError = suggestedStatus === Tooth_SuggestedTreatmentStatus.FAILED;

  return (
    <Button
      variant="tertiary"
      size="medium"
      onClick={handleGenerateSuggestedToothTreatment}
      className={cn(styles.container, className)}
      data-testid={testID}
    >
      {(isRegenerate || isError) &&
        formatMessage({
          id: 'suggestedTreatmentButton.redo',
          defaultMessage: 'Redo AI treatment',
        })}
      {isGenerate &&
        formatMessage({
          id: 'suggestedTreatmentButton.plan',
          defaultMessage: 'Plan AI Treatment',
        })}
    </Button>
  );
};
