import { useAppSelector, useMedia, useWindowSize } from '@/shared/hooks';

import { assetsModel, getFileSrc } from '@/entities/assets';
import { reportsModel } from '@/entities/reports';

import { calculateImageSize } from '../lib/calculateImageSize';

const PANO_IMAGE_HEIGHT_COEFFICIENT = 0.9;
const PANO_IMAGE_HEIGHT_COEFFICIENT_SMALL_WINDOW = 0.75;
const MODAL_PADDING = 220;

export const useGetTeethNumberingModalImage = () => {
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportID = report?.ID ?? '';

  const { width: wWidth, height: wHeight } = useWindowSize();
  const { isSmallDesktop } = useMedia();

  const coefficient = isSmallDesktop
    ? PANO_IMAGE_HEIGHT_COEFFICIENT_SMALL_WINDOW
    : PANO_IMAGE_HEIGHT_COEFFICIENT;

  const CBCTPanoramaImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const containerWidth = wWidth * coefficient;
  const containerHeight =
    (wHeight - MODAL_PADDING) * PANO_IMAGE_HEIGHT_COEFFICIENT;

  const CBCTGpImage =
    CBCTPanoramaImage?.Content.case === 'CBCTGPPanoramaReformatGeneral'
      ? CBCTPanoramaImage?.Content.value.PanoramaSingle
      : undefined;

  const CBCTGpImageDimensions =
    CBCTGpImage?.Metadata.case === 'Image'
      ? CBCTGpImage.Metadata.value
      : undefined;

  const CBCTPanoramaURL = getFileSrc(CBCTGpImage?.ID ?? '');

  const CBCTImageSize = calculateImageSize(
    containerWidth,
    containerHeight,
    CBCTGpImageDimensions?.Width ? Number(CBCTGpImageDimensions?.Width) : 0,
    CBCTGpImageDimensions?.Height ? Number(CBCTGpImageDimensions?.Height) : 0,
  );

  const CBCTPanoImage = {
    src: CBCTPanoramaURL,
    width: CBCTImageSize.width,
    height: CBCTImageSize.height,
    path: CBCTGpImage?.Path ?? '',
    zoom: CBCTImageSize.zoom,
  };

  return {
    CBCTPanoImage,
    modalWidth: CBCTImageSize?.width,
  };
};
