import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Tooth as ReportTooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { MedicalImageInterface } from '@/shared/config';
import {
  MedicalImageRender,
  ViewMIR,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { trackPageView } from '@/shared/lib';
import {
  Button,
  Column,
  Layout,
  MobileLayout,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import {
  assetsModel,
  getFileSrc,
  useGetMedicalImageGroups,
  useGetSelectedMedicalImages,
} from '@/entities/assets';
import { conditionModel, useToothConditions } from '@/entities/condition';
import { ConfirmModal } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';
import { reportsModel } from '@/entities/reports';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';

import { useApproveTooth } from '@/features/approveTooth';
import { ImageToolbar } from '@/features/imageToolbar/ui/ImageToolbar/ImageToolbar';
import { MaskFilters } from '@/features/renderMasks';
import { SimplifiedToothChart } from '@/features/simplifiedToothChart';
import {
  SelectedMedicalImageList,
  ToothCard,
  ToothCardFDA,
  useAddAndRemoveMedicalImage,
} from '@/features/ToothCard';

import { AddConditionModal } from '@/widgets/AddConditionModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { MobileHeader } from '@/widgets/Header';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { MIRenderModal } from '@/widgets/MIRenderModal';
import { TeethNumberingModal } from '@/widgets/TeethNumberingModal';
import { UploadedSlices } from '@/widgets/UploadedSlices';

import { SKELETON_DEFAULT_HEIGHT } from './config/constants';
import styles from './Tooth.module.scss';
import { ToothMPR } from './ui/ToothMPR/ToothMPR';

type ToothProps = {
  className?: string;
};

export const Tooth: FC<ToothProps> = (props) => {
  const { className } = props;

  const [approveButtonHovered, setApproveButtonHovered] = useState(false);

  const { reportID = '', patientID = '', toothID = '' } = useParams();
  const { isSmallDesktop, isMobile } = useMedia();

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID),
  );

  const isFDA = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const dispatch = useAppDispatch();

  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );

  const isConditionsLoaded = conditionLoading === 'succeeded';

  const CBCTGPMPRSubVolume = useAppSelector(
    assetsModel.selectors.selectMPRByToothID(toothID),
  );

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(
    tooth as ReportTooth,
  );
  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const { toggleMedicalImageSelection } = useAddAndRemoveMedicalImage();

  const isToothLoaded = !!tooth;

  const displayedToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  );

  const toothGroups = useGetMedicalImageGroups(toothID);

  const toggleSelected = (medicalImage: MedicalImageInterface) => {
    toggleMedicalImageSelection({
      toothID,
      medicalImage,
      selectedMedicalImages,
    });
  };

  // TODO: Make a selector just with checking missing condition code by tooth and return boolean.
  const { isMissingTooth } = useToothConditions({
    toothID: tooth?.ID ?? '',
  });

  const handleApproveButtonHover = (isHovered: boolean) => {
    setApproveButtonHovered(isHovered);
  };

  const isToothApprovedMessage = tooth?.IsApproved && !approveButtonHovered;
  const isToothDisapproveMessage = tooth?.IsApproved && approveButtonHovered;

  const isInvert = currentReport?.MedicalImageFeatures?.ViewOptions?.Invert;
  const showPanoramaSplitInsteadOfSingle =
    currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle;

  useEffect(() => {
    trackPageView('Tooth Page Viewed');
  }, []);

  const reset = async () => {
    MedicalImageRender.resetUndoStack('main');

    await dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: currentReport?.ID as string,
        MedicalImageViewOptions: new MedicalImageViewOptions({
          ...(isInvert && { Invert: false }),
        }),
      }),
    ).unwrap();
  };

  if (isMobile) {
    return (
      <>
        <MobileLayout>
          <MobileHeader />
          <MobileLayout.Main>
            {isToothLoaded ? (
              <ToothCard
                id={tooth.ID}
                patientID={patientID ?? ''}
                reportID={reportID ?? ''}
                showViewerButton={false}
                imageContainerComponent={
                  <SelectedMedicalImageList
                    medicalImages={selectedMedicalImages}
                    toothID={tooth?.ID ?? ''}
                  />
                }
              />
            ) : (
              <Skeleton width="100%" borderRadius="8px" height="46px" />
            )}

            {!panoImage ? (
              <Skeleton
                width="100%"
                borderRadius="8px"
                height={`${SKELETON_DEFAULT_HEIGHT}px`}
              />
            ) : (
              <ViewMIR viewName="main" id="report_render">
                <ImageToolbar
                  toolbarId="main"
                  controls={[
                    'expand',
                    'split',
                    'sharpness',
                    'brightness',
                    'invert',
                    'reset',
                    'editNumbers',
                  ]}
                  tooltipPosition="left"
                  stateLessHandlers={{ reset }}
                  initialState={{
                    split: !!showPanoramaSplitInsteadOfSingle,
                    invert: !!isInvert,
                    expand: false,
                    hideMarks: false,
                    activeControl: undefined,
                  }}
                />
                <MaskFilters />
              </ViewMIR>
            )}

            {isConditionsLoaded && (
              <WidgetCard>
                <div className={styles.mprHeader}>
                  <div className={styles.mprHeaderText}>
                    <h4 className="h4">
                      <FormattedMessage
                        id="tooth.mpr.abbreviation"
                        defaultMessage="MPR"
                      />
                    </h4>
                    <p className="p3">
                      <FormattedMessage
                        id="tooth.mpr"
                        defaultMessage="Multi-Planar Reconstruction"
                      />
                    </p>
                  </div>
                </div>
                {CBCTGPMPRSubVolume ? (
                  <ToothMPR
                    className={styles.mpr}
                    toothISONumber={tooth?.Numeration?.ISO as number}
                    isMissingTooth={isMissingTooth}
                    mprUrl={getFileSrc(CBCTGPMPRSubVolume?.Volume?.ID ?? '')}
                    toothID={toothID}
                    initialWw={CBCTGPMPRSubVolume?.WindowWidth}
                    initialWc={CBCTGPMPRSubVolume?.WindowLevel}
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    borderRadius="8px"
                    height={`${SKELETON_DEFAULT_HEIGHT}px`}
                  />
                )}
              </WidgetCard>
            )}

            {!isMissingTooth && (
              <WidgetCard>
                <MedicalImagesGroup
                  toothID={toothID}
                  selectedMedicalImages={selectedMedicalImages}
                  toggleSelected={toggleSelected}
                  groups={toothGroups}
                />
              </WidgetCard>
            )}

            <UploadedSlices toothID={toothID} toggleSelected={toggleSelected} />
          </MobileLayout.Main>
          <MobileLayout.Footer>
            <div className={styles.mobileFooterWrapper}>
              <Button
                onClick={() => toggleApproveTooth()}
                loading={isApproveToothLoading}
                onMouseEnter={() => handleApproveButtonHover(true)}
                onMouseLeave={() => handleApproveButtonHover(false)}
                className={styles.mobileApproveButton}
                disabled={!report?.YourPermissions?.CanChangeToothApproved}
                success={!approveButtonHovered && tooth?.IsApproved}
                danger={approveButtonHovered && tooth?.IsApproved}
              >
                {isToothApprovedMessage && (
                  <FormattedMessage
                    id="toothCard.approved"
                    defaultMessage="Approved"
                  />
                )}
                {isToothDisapproveMessage && (
                  <FormattedMessage
                    id="toothCard.disapprove"
                    defaultMessage="Disapprove"
                  />
                )}
                {!tooth?.IsApproved && (
                  <FormattedMessage
                    id="toothCard.approve"
                    defaultMessage="Approve"
                  />
                )}
              </Button>
            </div>
          </MobileLayout.Footer>
        </MobileLayout>

        {/* Modals */}
        <TeethNumberingModal />
        <MIRenderModal />
        <GetFullAccessModal />
        <AddConditionModal />
        <ConfirmModal />
      </>
    );
  }

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.leftColumnWrapper}>
                <div className={cn(styles.leftFixedColumn)}>
                  {isToothLoaded ? (
                    isFDA ? (
                      <ToothCardFDA
                        className={styles.mb16}
                        id={tooth.ID}
                        patientID={patientID ?? ''}
                        reportID={reportID ?? ''}
                        showViewerButton={false}
                        // TODO: [8|m] Temporary fast solution for demo. Should be refactored
                        imageContainerComponent={
                          <SelectedMedicalImageList
                            medicalImages={selectedMedicalImages}
                            toothID={tooth?.ID ?? ''}
                          />
                        }
                      />
                    ) : (
                      <ToothCard
                        className={styles.mb16}
                        id={tooth.ID}
                        patientID={patientID ?? ''}
                        reportID={reportID ?? ''}
                        showViewerButton={false}
                        imageContainerComponent={
                          <SelectedMedicalImageList
                            medicalImages={selectedMedicalImages}
                            toothID={tooth?.ID ?? ''}
                          />
                        }
                      />
                    )
                  ) : (
                    <Skeleton width="100%" borderRadius="8px" height="46px" />
                  )}

                  <WidgetCard className={cn(styles.panoImageWidget)}>
                    {!panoImage ? (
                      <Skeleton
                        width="100%"
                        borderRadius="8px"
                        height={`${SKELETON_DEFAULT_HEIGHT}px`}
                      />
                    ) : (
                      <ViewMIR
                        viewName="main"
                        id="report_render"
                        className={styles.viewMIR}
                      >
                        <ImageToolbar
                          toolbarId="main"
                          controls={[
                            'expand',
                            'split',
                            'sharpness',
                            'brightness',
                            'invert',
                            'reset',
                            'editNumbers',
                          ]}
                          tooltipPosition="left"
                          className={cn(styles.toolbar, styles.toolbarIn)}
                          stateLessHandlers={{ reset }}
                          initialState={{
                            split: !!showPanoramaSplitInsteadOfSingle,
                            invert: !!isInvert,
                            expand: false,
                            hideMarks: false,
                            activeControl: undefined,
                          }}
                        />
                        <MaskFilters className={styles.maskFilters} />
                      </ViewMIR>
                    )}
                  </WidgetCard>
                </div>
              </Column>

              {isConditionsLoaded && (
                <Column className={cn(styles.rightColumn)}>
                  <WidgetCard>
                    <div className={styles.mprHeader}>
                      <div className={styles.mprHeaderText}>
                        <h4 className="h4">
                          <FormattedMessage
                            id="tooth.mpr.abbreviation"
                            defaultMessage="MPR"
                          />
                        </h4>
                        <p className="p3">
                          <FormattedMessage
                            id="tooth.mpr"
                            defaultMessage="Multi-Planar Reconstruction"
                          />
                        </p>
                      </div>
                      {/* TODO: make full screen mode and connect this btn */}
                      {/* <button */}
                      {/*   className={styles.mprExpand} */}
                      {/*   type="button" */}
                      {/*   disabled={!CBCTGPMPRSubVolume} */}
                      {/*   onClick={() => */}
                      {/*     dispatch( */}
                      {/*       modalModel.actions.openMPRFullScreenModal( */}
                      {/*         getImageSrc(CBCTGPMPRSubVolume?.Volume?.ID), */}
                      {/*       ), */}
                      {/*     ) */}
                      {/*   } */}
                      {/* > */}
                      {/*   <Icon */}
                      {/*     name="expand" */}
                      {/*     size={32} */}
                      {/*   /> */}
                      {/* </button> */}
                    </div>

                    <ToothMPR
                      toothISONumber={tooth?.Numeration?.ISO as number}
                      isMissingTooth={isMissingTooth}
                      mprUrl={getFileSrc(CBCTGPMPRSubVolume?.Volume?.ID ?? '')}
                      toothID={toothID}
                      initialWw={CBCTGPMPRSubVolume?.WindowWidth}
                      initialWc={CBCTGPMPRSubVolume?.WindowLevel}
                    />
                  </WidgetCard>

                  {!isMissingTooth && (
                    <WidgetCard>
                      <MedicalImagesGroup
                        toothID={toothID}
                        selectedMedicalImages={selectedMedicalImages}
                        toggleSelected={toggleSelected}
                        groups={toothGroups}
                      />
                    </WidgetCard>
                  )}

                  <UploadedSlices
                    toothID={toothID}
                    toggleSelected={toggleSelected}
                  />
                </Column>
              )}
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter>
        <div className={styles.footerWrapper}>
          {/* TODO: [8|h] refactore to base ToothChart ui components */}
          <SimplifiedToothChart
            reportID={reportID ?? ''}
            patientID={patientID ?? ''}
            toothOfInterestNumber={displayedToothNumber as number}
          />

          {!isFDA && (
            <div className={styles.footerButtons}>
              {/* TODO: [2|m] refactore and move to ApproveToothButton feature */}
              <Button
                onMouseMove={() => handleApproveButtonHover(true)}
                onMouseLeave={() => handleApproveButtonHover(false)}
                size={isSmallDesktop || isMobile ? 'medium' : 'large'}
                icon={tooth?.IsApproved ? 'check' : 'plus'}
                loading={isApproveToothLoading}
                onClick={toggleApproveTooth}
                disabled={!report?.YourPermissions?.CanChangeToothApproved}
                success={!approveButtonHovered && tooth?.IsApproved}
                danger={approveButtonHovered && tooth?.IsApproved}
                className={cn(tooth?.IsApproved && styles.buttonApproved)}
              >
                {isToothApprovedMessage && (
                  <FormattedMessage
                    id="toothCard.approved"
                    defaultMessage="Approved"
                  />
                )}

                {isToothDisapproveMessage && (
                  <FormattedMessage
                    id="toothCard.disapprove"
                    defaultMessage="Disapprove"
                  />
                )}

                {!tooth?.IsApproved && (
                  <FormattedMessage
                    id="toothCard.approve"
                    defaultMessage="Approve"
                  />
                )}
              </Button>
            </div>
          )}
        </div>
      </Layout.Footer>

      <TeethNumberingModal />
      <MIRenderModal />
      <GetFullAccessModal />
      <AddConditionModal />
      <ConfirmModal />
    </>
  );
};
