import { createSelector } from '@reduxjs/toolkit';
import { compose } from 'ramda';

import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';

import { RootState } from '@/app/model/store';

import {
  filterTeethByReportID,
  filterTeethByReportIDAndROI,
  filterTeethByReportIDAndROIWithValidation,
  isPrimaryTooth,
  mapTeethToISONumbers,
} from '../lib';

import { toothAdapter } from './toothSlice';

const toothSelectors = toothAdapter.getSelectors(
  (state: RootState) => state.tooth,
);

export const { selectById, selectEntities } = toothSelectors;

export const selectToothByID = (toothID: string) => (state: RootState) =>
  selectById(state, toothID);

// IMPORTANT!!!
// TOOTH 404 USED FOR DELETED DETECTIONS
export const selectAll = createSelector(toothSelectors.selectAll, (teeth) =>
  teeth.filter((tooth) => tooth?.Numeration?.ISO !== 404),
);

export const select404ByReportID = (reportID: string) =>
  createSelector(selectAllDeleted, (teeth) => {
    const deletedToothByReportID = teeth.find(
      (tooth) => tooth.ReportID === reportID,
    );

    return deletedToothByReportID;
  });

export const selectAllDeleted = (state: RootState) =>
  toothSelectors
    .selectAll(state)
    .filter((tooth) => tooth?.IsRemoved || tooth?.Numeration?.ISO === 404);

export const selectByReportID = (reportID: string) =>
  createSelector(selectAll, (teeth) => filterTeethByReportID(teeth, reportID));

export const selectReportAllIds = (reportID: string) =>
  createSelector(selectByReportID(reportID), (teeth) =>
    teeth.filter((tooth) => !tooth?.IsRemoved).map((tooth) => tooth.ID),
  );

export const selectDeletedByReportID = (reportID: string) =>
  createSelector(selectAllDeleted, (teeth) =>
    teeth.filter((tooth) => tooth?.ReportID === reportID),
  );

export const selectReportROITeeth = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByReportIDAndROI(teeth, reportID),
  );

export const selectIsAllReportTeethApproved = (reportID: string) =>
  createSelector(selectAll, (teeth) => {
    const reportROITeeth = filterTeethByReportIDAndROI(teeth, reportID);

    return reportROITeeth.every((tooth) => tooth.IsApproved);
  });

export const selectReportROIValidTeeth = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByReportIDAndROIWithValidation(teeth, reportID),
  );

export const selectAllTeethIsApproved = (reportID: string) =>
  createSelector(selectReportROITeeth(reportID), (teeth) =>
    teeth.every((tooth) => tooth.IsApproved),
  );

export const selectReportROITeethIDs = (reportID: string) =>
  createSelector(selectReportROITeeth(reportID), (teeth) =>
    teeth.map((tooth) => tooth.ID),
  );

export const selectISONumbersByReportIDAndROI = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    compose(mapTeethToISONumbers, filterTeethByReportIDAndROI)(teeth, reportID),
  );

export const selectLocalROITeethISONumbers = (state: RootState) =>
  state.tooth.localROITeethISONumbers;

export const selectLocalROITeethIDs = (state: RootState) =>
  state.tooth.localROITeethIDs;

export const selectToothChartCustomMode = (state: RootState) =>
  state.tooth.toothChartCustomMode;

export const selectUpdatingROI = (state: RootState) => state.tooth.updatingROI;

export const selectLoading = (state: RootState) => state.tooth.loading;

export const selectDisplaySlicesByToothID = (toothID: string) =>
  createSelector(
    selectEntities,
    (toothEntities) => toothEntities[toothID]?.DisplaySlices ?? [],
  );

const collectPrimaryTeethISONumbers = (teeth: Tooth[]) =>
  teeth.reduce(
    (collection, tooth) => {
      const ISONumber = tooth.Numeration?.ISO ?? 0;

      if (isPrimaryTooth(ISONumber)) {
        collection[ISONumber] = ISONumber;
      }

      return collection;
    },
    {} as Record<number, number>,
  );

export const selectTeethToShow = (reportID: string) =>
  createSelector(selectReportROIValidTeeth(reportID), (teeth) => {
    const primaryISONumbers = collectPrimaryTeethISONumbers(teeth);

    return teeth.filter((tooth) => {
      const ISONumber = (tooth.Numeration?.ISO ?? 0) + 40;

      if (ISONumber in primaryISONumbers) {
        return false;
      }

      return !tooth.IsRemoved;
    });
  });

export const selectTeethIDsToShow = (reportID: string) =>
  createSelector(selectTeethToShow(reportID), (teeth) =>
    teeth.map((tooth) => tooth.ID),
  );

export const selectToothSuggestedTreatment = (toothID: string) =>
  createSelector(
    selectEntities,
    (toothEntities) => toothEntities[toothID]?.SuggestedTreatment,
  );
